import React from 'react';

import { graphql, navigate } from 'gatsby';

import { Button } from '../../components/Atoms/Button/Button';
import {
  ButtonContainer,
  ImageParentContainer,
  LogoContainer,
  ParentContainer,
  StyledCaption,
  StyledContainer,
  StyledH1,
  StyledH1Container,
  StyledH2,
  StyledH2Container,
} from '../../components/Atoms/StyledComponents';
import { SiteGrid } from '../../components/SiteGrid/SiteGrid';
import Wrapper from '../../components/Wrapper/Wrapper';
import EzraLogo from '../../images/svgs/EzraLogo.svg';

const NotFoundGb = ({ data, appearance }) => {
  const ourData = data?.allContentfulContentMicrocopy?.nodes?.[0];
  const ezratheme = appearance?.theme || 'Dark';

  return (
    <ParentContainer ezratheme={ezratheme}>
      <Wrapper>
        <SiteGrid>
          <StyledContainer>
            <StyledCaption>{ourData?.pageNotFoundThatsAnError}</StyledCaption>
          </StyledContainer>
          <StyledH1Container>
            <StyledH1>{ourData?.pageNotFoundTitle}</StyledH1>
          </StyledH1Container>
          <StyledH2Container>
            <StyledH2>{ourData?.pageNotFoundBody}</StyledH2>
          </StyledH2Container>
          <ButtonContainer>
            <Button
              onClick={() => {
                navigate('/en-gb');
              }}
              variant="primary"
              size="small"
              ezratheme={ezratheme}
            >
              {ourData.pageNotFoundGoToHomePage}
            </Button>
            <Button
              onClick={() => {
                navigate(-1);
              }}
              variant="primary"
              size="small"
              ezratheme={ezratheme}
            >
              {ourData?.pageNotFoundGoBack}
            </Button>
          </ButtonContainer>
        </SiteGrid>
      </Wrapper>
      <ImageParentContainer>
        <Wrapper>
          <SiteGrid>
            <LogoContainer>
              <EzraLogo />
            </LogoContainer>
          </SiteGrid>
        </Wrapper>
      </ImageParentContainer>
    </ParentContainer>
  );
};

export default NotFoundGb;

export const data = graphql`
  query NotFoundGb {
    allContentfulContentMicrocopy(filter: { node_locale: { eq: "en-GB" } }) {
      nodes {
        ...MicroCopyFragment
      }
    }
  }
`;
